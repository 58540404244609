<template>
    <KCourse loader-class="MBcont" course-id="0" title="Чек-листы по эмоциям" :items="items">
        <div class="KImage" :class="$mq"><KImage :src="require('@/assets/membersh/FaceCheck/Artboard 1.jpg')" alt="" width="100%"/></div>
        <div class="KImage" :class="$mq"><KImage :src="require('@/assets/membersh/FaceCheck/Artboard 2.jpg')" alt="" width="100%"/></div>
        <div class="KImage" :class="$mq"><KImage :src="require('@/assets/membersh/FaceCheck/Artboard 3.jpg')" alt="" width="100%"/></div>
        <div class="KImage" :class="$mq"><KImage :src="require('@/assets/membersh/FaceCheck/Artboard 4.jpg')" alt="" width="100%"/></div>
        <div class="KImage" :class="$mq"><KImage :src="require('@/assets/membersh/FaceCheck/Artboard 5.jpg')" alt="" width="100%"/></div>
        <div class="KImage" :class="$mq"><KImage :src="require('@/assets/membersh/FaceCheck/Artboard 6.jpg')" alt="" width="100%"/></div>
        <div class="KImage" :class="$mq"><KImage :src="require('@/assets/membersh/FaceCheck/Artboard 8.jpg')" alt="" width="100%"/></div>
        <div class="KImage" :class="$mq"><KImage :src="require('@/assets/membersh/FaceCheck/Artboard 9.jpg')" alt="" width="100%"/></div>
        <div class="KImage" :class="$mq"><KImage :src="require('@/assets/membersh/FaceCheck/Artboard 10.jpg')" alt="" width="100%"/></div>
        <div class="KImage" :class="$mq"><KImage :src="require('@/assets/membersh/FaceCheck/Artboard 11.jpg')" alt="" width="100%"/></div>
    </KCourse>
</template>

<script>
    import KCourse from "../../../Widgets/KCourse";
    import KImage from "../../../Widgets/KImage";

    export default {
        name: "MBCourseHead",
        components: {
            KCourse,
            KImage
        },
        data() {
        return {
            items: [
                { title: "База лица", url:"/mycourses/face-emotions/1"},
                { title: "Улыбка", url:"/mycourses/face-emotions/2"},
                { title: "Обида", url:"/mycourses/face-emotions/3"},
                { title: "Удивление", url:"/mycourses/face-emotions/4"},
            ],
        }
        }
    }
</script>

<style scoped>
    .KImage{ 
        width: 80%;
        margin: 0 auto 50px auto;
        display: block;
    }
    .KImage.mobile, .KImage.laptop{
        width:100%
    }

    .container {
        position: relative;
        width: 100%;
        height: 0;
        padding-bottom: 56.25%;
        box-sizing: border-box;
        margin-bottom: 1em;
    }
    .video {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }

</style>